<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-nav tabs>
        <b-nav-item active>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <br>
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" class="card-accent-primary">
            <div slot="header">
              <b-dropdown variant="primary">
                <template slot="button-content"></template>
                <b-dropdown-item @click="LogOutSocketMessage('all', '')">Tüm kullanıcılara çıkış yap</b-dropdown-item>
                <b-dropdown-item @click="LogOutSocketMessage('selected', '')">Seçili kullanıcılara çıkış yap</b-dropdown-item>
                <b-dropdown-item @click="ReloadSocketMessage('all', '')">Tüm kullanıcı sayfalarını yeniden yükle</b-dropdown-item>
                <b-dropdown-item @click="ReloadSocketMessage('selected', '')">Seçili kullanıcı sayfalarını yeniden yükle</b-dropdown-item>
                <b-dropdown-item @click="SendMessage('all', '')" v-if="SendingText">Tüm kullanıcılara mesajı gönder</b-dropdown-item>
                <b-dropdown-item @click="SendMessage('selected', '')" v-if="SendingText">Seçili kullanıcılara mesajı gönder</b-dropdown-item>
              </b-dropdown>
              Socket aktif verisi
            </div>
            <b-form-textarea id="textarea" v-model="SendingText" placeholder="Mesajınızı yazınız..." rows="3" max-rows="6"></b-form-textarea>
            <b-table stacked="md" :items="all_users" :fields="socket_fields">
              <template v-slot:cell(selected_users)="row">
                <input type="checkbox" name="selected_users" v-model="selected_users" :value="row.item.username">
              </template>
              <template v-slot:cell(username)="row"> {{ row.item.username }} </template>
              <template v-slot:cell(hospital_id)="row">
                <template v-if="SocketList[row.item.username] !== undefined"> {{ SocketList[row.item.username].hospital_group + '/' + SocketList[row.item.username].hospital_id }} </template>
              </template>
              <template v-slot:cell(name_surname)="row"> {{ row.item.first_name + ' ' + row.item.last_name }} </template>
              <template v-slot:cell(socket)="row">
                <template v-if="SocketList[row.item.username] !== undefined">
                  <b-row v-for="(socket, socket_ind, index) in SocketList[row.item.username].socket" :key="'socket_list_' + socket_ind">
                    <b-col cols="4"> {{ socket_ind }}</b-col>
                    <b-col cols="4">{{ socket.p_id }}</b-col>
                    <b-col cols="4"> {{ socket.r_n }}</b-col>
                  </b-row>
                </template>
              </template>
              <template v-slot:cell(status)="row">
                <b-badge v-if="SocketList[row.item.username] !== undefined" variant="success">aktif</b-badge>
                <b-badge v-else variant="danger">pasif</b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/services/users";
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "SystemTraffic",
  computed: {
    ...mapGetters({
      lang: "lang"
    })
  },
  data() {
    return {
      SendingText: '',
      user: {},
      selected_users: [],
      SocketList: {},
      all_users: {},
      socket_fields: [
        { key: "selected_users", label: "" },
        { key: "username", label: "Kullanıcı Adı" },
        { key: "name_surname", label: "Ad Soyad" },
        { key: "hospital_id", label: "Hastane Kodu" },
        { key: "status", label: "Durum" },
        { key: "socket", label: "Socket verisi (Socket Id, Hasta Id, Route Adı)" }
      ]
    };
  },
  mounted: function() {
    this.$options.sockets.onmessage = function(message) {
      let socket_data = JSON.parse(message.data);
      if (socket_data.type === 'SocketList') {
        this.SocketList = socket_data.data;
        // console.log('this.SocketList', this.SocketList);
      }
    };
  },
  created: function() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    this.getSocketData();
  },
  methods: {
    getSocketData: function() {
      this.$socket.send(JSON.stringify({ 'type': 'getSocketData' }));
    },
    LogOutSocketMessage: function(population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'logout', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username }));
    },
    ReloadSocketMessage: function(population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'reload', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username }));
    },
    SendMessage: function(population, username) {
      this.$socket.send(JSON.stringify({ 'type': 'SendingText', 'population': population, 'selected_users': this.selected_users, 'admin': this.user.username, 'SendingText': this.SendingText }));
    }
  },
  watch: {},
  components: {
    Modal
  }
};

</script>

<style type="text/css">


</style>

